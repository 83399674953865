import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

// Create a new store instance.
const store = createStore({
	state () {
		return {
			token: "",
			isLogged: false,
			user: {},
			expires_at: null,
			colorScheme: 'light',
			menu: [],
		}
	},

	getters: {
		getToken: state => {
			return state.token;
		},

		getColorScheme: state => {
			return state.colorScheme;
		},

		// GETTING USER 
		getUser: state => {
			return state.user;
		},

		// GETTING STATE OF USER
		getLogged: state => {
			return state.isLogged;
		},

	},

	mutations: {
		// SETTING LOGIN
		SET_LOGGED(state, payload) {
			state.isLogged = payload.isLogged;
			state.user = payload.user;
			state.token = payload.token;
			state.expires_at = payload.expires_at;
		},
		
		// SETTING LOGOUT
		SET_LOGGOUT(state, payload) {			
			state.token = payload.token;
			state.user = payload.user;
			state.isLogged = payload.isLogged;
			state.expires_at = payload.expires_at;
		},

		SET_COLORSCHEME(state, payload) {			
			state.colorScheme = payload.colorScheme;
		},
	},

	actions: {
		setLogged(context, payload) {
			context.commit('SET_LOGGED', payload);
		},

		// SETTING LOGOUT
		setLoggout(context, payload) {
			context.commit('SET_LOGGOUT', payload);
		},

		setColorScheme(context, payload) {
			context.commit('SET_COLORSCHEME', payload);
		},
	},

	plugins: [createPersistedState()],
})

export default store