<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active' : sidebarActive}"
        @click="onMenuClick" @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/"> 
                <img v-if="this.$appState.colorScheme == 'dark'" src="layout/images/logoWhite.svg" alt="giro da sorte">
                <img v-if="this.$appState.colorScheme == 'light'" src="layout/images/logoBlack.svg" alt="giro da sorte">
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu class="layout-menu" :items="menu" :layoutMode="layoutMode" :parentMenuItemActive="true" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
        </div>
    </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
export default {
    name: "AppMenu",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },

    created() {
        this.routeList = this.$router.getRoutes()
        this.loadMenu()
    },

    data() {
        return {
            menu: [],
            routeList: []
        }
    },

    methods: {
        onSidebarMouseOver() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                this.$emit("sidebar-mouse-over");
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit("sidebar-mouse-leave");
                }, 250);
            }
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event);
        },
        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		},

        loadMenu() {            

            const menuRaw = [
                {
                    label: "Dashboard", 
                    icon: "fa-regular fa-house",
                    to: "/dashboard", 
                    routeName: 'dashboard'
                },
                {
                    label: "Usuários", icon: "fa-regular fa-users",
                    items: [
                        { routeName: "admins"},
                        { routeName: "managers"},
                        { routeName: "operators"},
                        { routeName: "sellers"},
                    ],
                },
                {
                    label: "Cadastros", icon: "fa-regular fa-folder-plus",
                    items: [
                        { routeName: "regions"},
                        { routeName: "sections"},
                        { routeName: "providers"},
                        { routeName: "genres"},
                        { routeName: "games"},
                        { routeName: "collections"},
                        { routeName: "expense_categories"}
                    ],
                },
                {
                    label: "Financeiro", icon: "fa-regular fa-sack-dollar",
                    items: [
                        { routeName: "expense_schedules"},
                        { routeName: "expenses"},
                        { routeName: "charge" }
                    ],
                },
                {
                    label: "Relatórios", icon: "fa-regular fa-file-signature",
                    items: [
                        { routeName: "transactions"},
                        { routeName: "player_sessions"},
                        { routeName: "sellers_report"},

                        {
                            label: "Fechamentos",
                            icon: "fa-regular fa-file-signature",
                            items: [
                                { routeName: "closures"},
                                { routeName: "sellers_closures"},
                                { routeName: "operators_closures"},
                            ]
                        },
                        
                        {
                            label: "Cassino",
                            icon: "fa-regular fa-file-signature",
                            items: [
                                { routeName: "games_report"},
                                { routeName: "providers_report"},
                                { routeName: "region_casino_report" }
                            ]
                        },
                    ],
                },
                {
                    label: "Auditoria", 
                    icon: "fa-regular fa-memo-pad", 
                    to: "/logs", 
                    routeName: 'logs'
                },
                {
                    label: "Configurações", 
                    icon: "fa-regular fa-gear",
                    to: "/settings", 
                    routeName: 'settings'
                },
                {
                    label: "Atualizações", 
                    icon: "fa-regular fa-notes", 
                    to: "/change_logs", 
                    routeName: 'change_logs'
                },
                

            ]
            
            this.$store.state.menu = []
            this.menu = this.getRouteInfoFromItems(menuRaw)
            this.clearEmptyGroups(this.menu)
        },

        clearEmptyGroups(items){
            for (let item of items) {
                if (Object.prototype.hasOwnProperty.call(item, 'items')){
                    if (item.items.every((e)=>e.visible == false)) {
                        item.visible = false
                    }
                    this.clearEmptyGroups(item.items)
                }
            }
        },

        getRouteInfoFromItems(items){

            for (let item of items) {

                if (Object.prototype.hasOwnProperty.call(item, 'routeName')){
                    const routeItem = this.routeList.find(route => route.name == item.routeName)

                    if (routeItem) {
                        let hasPermission = true

                        if(routeItem.meta.resource){
                            hasPermission = this.hasPermission(routeItem.meta.action || 'list', routeItem.meta.resource)
                        }
                        
                        if(hasPermission) {   
                            item.icon = routeItem.meta.icon,
                            item.label = routeItem.meta.readableName,
                            item.to = routeItem.path
                        } else{
                            item.visible = false
                        }
                    }

                    else {
                        item.icon = 'fa-solid fa-bug',
                        item.label = 'Erro',
                        item.to = '/'
                    }
                    
                }
                if (Object.prototype.hasOwnProperty.call(item, 'items')){
                    this.getRouteInfoFromItems(item.items)
                }else{
                    if (item.visible != false) {
                        this.$store.state.menu.push(item)
                    }
                }
            }

            return items
        }

    },
    components: { AppSubmenu },
};
</script>