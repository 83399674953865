import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
        path: '/login',
        name: 'login',
        component: () => import('./views/Login.vue')
    },
	{
        path: '/profile',
        name: 'profile',
        component: () => import('./views/Profile.vue')
    },
	{
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./views/Dashboard.vue'),
		meta: {
			requireAuth: true,
			readableName: 'Dashboard',
			resource: 'dashboard',
			icon: "fa-regular fa-house",
		},
    },
	{
        path: '/settings',
        name: 'settings',
        component: () => import('./views/Settings.vue'),
		meta: {
			requireAuth: true,
			readableName: 'Configurações',
			resource: 'settings',
			icon: "fa-regular fa-gear",
		},
    },
	{
        path: '/logs',
        name: 'logs',
        component: () => import('./views/Logs.vue'),
		meta: {
			requireAuth: true,
			readableName: 'Auditoria',
			resource: 'logs',
			icon: "fa-regular fa-memo-pad", 
		},
    },
	{
        path: '/change_logs',
        name: 'change_logs',
        component: () => import('./views/ChangeLogs.vue'),
		meta: {
			requireAuth: true,
			readableName: 'Atualizações',
			resource: 'change_logs',
			icon: "fa-regular fa-clock-rotate-left", 
		},
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./views/NotFound.vue')
    },
    {	
		path: '/users',
		name: 'users',
		meta: {
			requireAuth: true,
			icon: 'fa-duotone fa-user-tie',
		},
        children: [
            {
				path: 'admins',
				name: 'admins',
				component: () => import("./views/User/Admins"),
				meta: {
					requireAuth: true,
					readableName: 'Admins',
					resource: 'admins'
				},
			},
            {
				path: 'managers',
				name: 'managers',
				component: () => import("./views/User/Managers"),
				meta: {
					requireAuth: true,
					readableName: 'Gerentes',
					resource: 'managers'
				},
			},
            {
				path: 'operators',
				name: 'operators',
				component: () => import("./views/User/Operators"),
				meta: {
					requireAuth: true,
					readableName: 'Operadores',
					resource: 'operators'
				},
			},
            {
				path: 'sellers',
				name: 'sellers',
				component: () => import("./views/User/Sellers"),
				meta: {
					requireAuth: true,
					readableName: 'Revendedores',
					resource: 'sellers'
				},
			},
        ]
    },
	{	
		path: '/register',
		name: 'register',
		meta: {
			requireAuth: true,
			icon: 'fa-duotone fa-user-tie',
		},
        children: [
            {
				path: 'regions',
				name: 'regions',
				component: () => import("./views/Register/Regions"),
				meta: {
					requireAuth: true,
					readableName: 'Regiões',
					resource: 'regions'
				},
			},
			{
				path: 'sections',
				name: 'sections',
				component: () => import("./views/Register/Sections"),
				meta: {
					requireAuth: true,
					readableName: 'Seções',
					resource: 'sections'
				},
			},
			{
				path: 'providers',
				name: 'providers',
				component: () => import("./views/Register/Providers"),
				meta: {
					requireAuth: true,
					readableName: 'Provedores',
					resource: 'providers'
				},
			},
			{
				path: 'games',
				name: 'games',
				component: () => import("./views/Register/Games"),
				meta: {
					requireAuth: true,
					readableName: 'Jogos',
					resource: 'games'
				},
			},
			{
				path: 'genres',
				name: 'genres',
				component: () => import("./views/Register/Genres"),
				meta: {
					requireAuth: true,
					readableName: 'Gêneros',
					resource: 'genres'
				},
			},
			{
				path: 'collections',
				name: 'collections',
				component: () => import("./views/Register/Collections"),
				meta: {
					requireAuth: true,
					readableName: 'Coleções',
					resource: 'collections'
				},
			},
			{
				path: 'expense_categories',
				name: 'expense_categories',
				component: () => import("./views/Register/ExpenseCategories"),
				meta: {
					requireAuth: true,
					readableName: 'Categorias de despesas',
					resource: 'expenses'
				},
			},
        ]
    },
	{	
		path: '/financial',
		name: 'financial',
		meta: {
			requireAuth: true,
			icon: 'fa-duotone fa-user-tie',
		},
        children: [
            {
				path: 'expense_schedules',
				name: 'expense_schedules',
				component: () => import("./views/Financial/ExpenseSchedules"),
				meta: {
					requireAuth: true,
					readableName: 'Agendamento de despesas',
					resource: 'expenses'
				},
			},
			{
				path: 'expenses',
				name: 'expenses',
				component: () => import("./views/Financial/Expenses"),
				meta: {
					requireAuth: true,
					readableName: 'Despesas',
					resource: 'expenses'
				},
			},
			{
				path: 'charge',
				name: 'charge',
				component: () => import("./views/Financial/Charge"),
				meta: {
					requireAuth: true,
					readableName: 'Cobrança',
					resource: 'charge'
				},
			},
        ]
    },
	{	
		path: '/reports',
		name: 'reports',
		meta: {
			requireAuth: true,
			icon: 'fa-duotone fa-user-tie',
		},
        children: [
            {
				path: 'sellers_report',
				name: 'sellers_report',
				component: () => import("./views/Report/SellersReport"),
				meta: {
					requireAuth: true,
					readableName: 'Por Revendedor',
					resource: 'sellers_report'
				},
			},
			{
				path: 'player_sessions',
				name: 'player_sessions',
				component: () => import("./views/Report/PlayerSessionsReport"),
				meta: {
					requireAuth: true,
					readableName: 'Sessões de jogo',
					resource: 'player_sessions'
				},
			},
			{
				path: 'transactions',
				name: 'transactions',
				component: () => import("./views/Report/TransactionsReport"),
				meta: {
					requireAuth: true,
					readableName: 'Extrato',
					resource: 'transactions'
				},
			},
			
			{
				path: 'closures',
				name: 'closures',
				component: () => import("./views/Report/Closures/ClosuresReport"),
				meta: {
					requireAuth: true,
					readableName: 'Fechamentos',
					resource: 'closures_report'
				},
			},
			{
				path: 'sellers_closures',
				name: 'sellers_closures',
				component: () => import("./views/Report/Closures/SellersClosuresReport"),
				meta: {
					requireAuth: true,
					readableName: 'Por Revendedor',
					resource: 'sellers_closures_report'
				},
			},
			{
				path: 'operators_closures',
				name: 'operators_closures',
				component: () => import("./views/Report/Closures/OperatorsClosuresReport"),
				meta: {
					requireAuth: true,
					readableName: 'Por Operador',
					resource: 'operators_closures_report'
				},
			},

			{
				path: '/casino/regions_report',
				name: 'region_casino_report',
				component: () => import("./views/Report/Casino/RegionsCasinoReport"),
				meta: {
					requireAuth: true,
					readableName: 'Por Região',
					resource: 'region_casino_report'
				},
			},
			{
				path: '/casino/games_report',
				name: 'games_report',
				component: () => import("./views/Report/Casino/GamesCasinoReport"),
				meta: {
					requireAuth: true,
					readableName: 'Por Jogo',
					resource: 'games_report'
				},
			},
			{
				path: '/casino/providers_report',
				name: 'providers_report',
				component: () => import("./views/Report/Casino/ProvidersCasinoReport"),
				meta: {
					requireAuth: true,
					readableName: 'Por Provedor',
					resource: 'providers_report'
				},
			},
        ]
    }
];

const altDomainRoutes = [
	{
        path: '/',
        redirect: '/baixar',
    },
	{
        path: '/baixar',
        name: 'download',
        component: () => import('./views/Download.vue')
    },
]

const isAltDomain = window.location.hostname == 'girodasorte.app'

const router = createRouter({
    history: createWebHashHistory(),
    routes: isAltDomain ? altDomainRoutes : routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

import store from "./store/store";

router.beforeEach((to, from, next) => {
	const isLogged = store.getters.getLogged

	if (isAltDomain) {
		if (to.path != '/baixar'){
			next('/baixar')
			return
		}
		next();
		return
	}

	if(to.path == '/' && !isLogged) {
		next('/login')
		return
	}

	if (to.meta.requireAuth) {
				
		if (!isLogged) {
			next('/login');
		} else {
			next();
		}

	} else {
		next();
	}
});

export default router;