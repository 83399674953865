import store from "../store/store";
import router from '../router'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment'

export default {
    methods: {
        success(msg = "Sucesso!") {
            this.$toast.add({ severity: 'success', summary: msg, life: 3000 });
        },
        error(msg = "Tente novamente.") {
            this.$toast.add({ severity: 'error', summary: msg, life: 3000 });
        },
        info(msg = "!") {
            this.$toast.add({ severity: 'info', summary: msg, life: 3000 });
        },
        warn(msg = "Atenção") {
            this.$toast.add({ severity: 'warn', summary: msg, life: 3000 });
        },

        responseError(response, fallbackMessage){
            if (response?.data?.message) return this.error(response.data.message.replace('E_AUTHORIZATION_FAILURE: ',''))
            if (response?.data?.errors) {
                if (response.data.errors[0]) {
                    return this.error(response.data.errors[0].message.replace('E_UNAUTHORIZED_ACCESS: Unauthorized access','Faça login novamente para continuar') || fallbackMessage)
                }
            }

            this.error(fallbackMessage)
        },

        exportExcel(filename = 'nome arquivo') {
            var downloadLink;
            var dataType = 'application/vnd.ms-excel';
            var tableSelect = document.querySelector('table');
            var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

            filename = filename?filename+'.xls':'excel_data.xls';
            downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            if(navigator.msSaveOrOpenBlob) {
                var blob = new Blob(['\ufeff', tableHTML], {
                    type: dataType
                });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
                downloadLink.download = filename;
                downloadLink.click();
            }

        },

        exportPDF(title = 'Relatório', period) {
            // Captura a tabela
            var table = document.querySelector('.p-datatable-table');
        
            // Captura o cabeçalho da tabela
            var headers = Array.from(table.querySelectorAll('thead tr th')).map(th => th.innerText.trim());
        
            // Captura os dados do corpo da tabela
            var data = Array.from(table.querySelectorAll('tbody tr')).map(tr => {
                return Array.from(tr.querySelectorAll('td')).map(td => td.innerText.trim());
            });

            // Captura a linha de total, se existir
            var totalRow = Array.from(table.querySelectorAll('tfoot tr')).map(tr => {
                return Array.from(tr.querySelectorAll('td')).map(td => td.innerText.trim());
            })[0];
        
            // Cria o documento pdf
            var doc = new jsPDF('l', 'pt', 'a4');
        
            // Define a data da impressão
            var date = new Date();
        
            var pdfTitle = period ? `${title} - ${period}` : `${title}`
        
            try {
                doc.autoTable({
                    head: [headers],
                    body: data,
                    foot: [totalRow],
                    footStyles: {
                        textColor: [255, 255, 255]
                    },
                    addPageContent: function (data) {
                        doc.setFontSize(20)
                        doc.text(pdfTitle, data.settings.margin.left, 30);
                        doc.setFontSize(14)
                        doc.text("Impresso em: " + date.toLocaleString('pt-BR'), data.settings.margin.left, 570);
                        doc.text("Página " + data.pageNumber + " de " + data.pageCount, data.settings.margin.left + 680, 570);
                    }
                });
            } catch (error) {
                console.log(error)
            }
        
            doc.output('dataurlnewwindow');
        },

        // DO LOGOUT 
        logout() {
            store.dispatch('setLoggout', {
                user: {},
                token: "",
                isLogged: false,
                expires_at: null
            });

            router.push({
                path: '/login'
            });
        },      

        // FORMAT DATE 
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY')
        },

        humanDate(input, locale = 'pt-br') {
            if (locale === 'pt') locale = 'pt-br'
            moment.locale(locale)
            return moment(input).fromNow()
        },

        formatDateFull(date) {
            return moment(date).format('DD/MM/YYYY HH:mm')
        },

        // MONEY FORMAT
        formatCurrency(value) {
            if(!value) value = 0
            return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
		},

        // PORCENTAGE FORMAT
        formatPorcentage(value) {
            value = Math.abs(value);
            return value + '%';
        },

        // RETURN TOKEN
        accessToken() {
            let token = store.getters.getToken;
            return token;
        },

        // RETURN USER LOGGED
        loggedUser() {
            let user = store.getters.getUser;
            return user;
        },

        getResourceTranslation(resource) {

            const resourceTranslations = {
                admins: 'Admins',
                regions: 'Regiões',
                managers: 'Gerentes',
                sections: 'Seções',
                sellers: 'Revendedores',
                operators: 'Operadores',
                comissions: 'Comissões',
                transactions: 'Transações',
                games: 'Jogos',
                genres: 'Gêneros',
                collections: 'Coleções',
                expenses: 'Despesas',
                devices: 'Dispositivos',
                providers: 'Provedores de jogos',
                closures: 'Fechamentos',
                player_sessions: 'Sessões de jogo',
                charge: 'Cobrança',
                regions_report: 'Relatório de região',
                sections_report: 'Relatório de seção',
                games_report: 'Relatório de jogos',
                sellers_report: 'Relatório de revendedor',
                dashboard: 'Dashboard',
                change_logs: 'Atualizações',
                providers_report: 'Relatório de provedores',
                sellers_closures_report: 'Relatório de fechamentos por revendedores',
                operators_closures_report: 'Relatório de fechamentos por operadores',
            };
            
            return resourceTranslations[resource] || resource;
        },

        hasPermission(action, resource) {
            if(!store?.state?.user?.permissions) return false

            return (!!store.state.user.permissions.find((permission) => {
                return permission.action == action && permission.resource == resource 
            }))
            
        },

        formatDateFilter(dateArray){
            if(!dateArray) return

            let dateFilter = [...dateArray]
            
            if (dateFilter.length == 2) {
                if(dateFilter[1] == null) {
                    dateFilter[1] = dateFilter[0]
                    dateArray[1] = dateArray[0]
                }
            }

            return dateFilter
        },
    },
}