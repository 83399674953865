<template>
    <Toast/>

	<div class="login-body">
		<div class="login-wrapper">
			<form class="login-panel" @submit.prevent="onSubmitLogin()">
                <InputText v-model="username" id="username" placeholder="Usuário" />
                <Password v-model="password" id="password" placeholder="Senha" :feedback="false" />
                <Button :loading="loading" label="ENTRAR" type="submit"></Button>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    name : "Login",

    data () {
        return {
            username: null,
            password: null,
            loading: false,
        }
    },

    methods: {
        onSubmitLogin() {
            this.loading = true;

            this.$api
                .post("/login", {
                    username: this.username,
                    password: this.password
                })
                .then(response => {
                    this.$store.dispatch("setLogged", {
                        isLogged: true,
                        user: {...response.data.user, permissions: response.data.permissions, operator: response.data.operator},
                        token: response.data.token,
                        expires_at: response.data.expires_at
                    });
                    
                    if (response.data.permissions.find((permission) => permission.resource == 'dashboard')) {
                        this.$router.push({
                            name: 'dashboard'
                        })
                    } else {
                        this.$router.push({
                            name: 'profile'
                        })
                    }
                })
                .catch((error) => {
                    this.responseError(error.response, 'Erro ao fazer login');
                })
                .finally(() => {
                    this.loading = false
                })
        
        },
    }
};
</script>
