<template>
    <div class="p-5">
        <div class="flex flex-wrap gap-5 align-items-center justify-content-center">
            <div class="w-full p-5 border-round max-w-30rem surface-card flex flex-column gap-3">
                <div class="flex align-items-center">
                    <div class="p-3">
                        <i class="text-white p-3 bg-blue-400 border-round text-5xl fa-regular fa-mobile"></i>
                    </div>

                    <div class="flex flex-column gap-2">
                        <div class="font-bold">
                            APLICATIVO REVENDEDOR
                        </div>

                        <div class="font-bold" v-if="!loading">
                            Versão Atual: {{ sellerApp?.current_version }}
                        </div>

                        <div v-else><i class="fa-duotone fa-spin fa-spinner-third"></i></div>
                    </div>
                </div>

                <Button @click="download(sellerApp.apk_url)" :loading="loading" icon="fa-brands fa-android" label="BAIXAR" />
            </div>

            <div class="w-full p-5 border-round max-w-30rem surface-card flex flex-column gap-3">
                <div class="flex align-items-center">
                    <div class="p-3">
                        <i class="text-white p-3 bg-green-400 border-round text-5xl fa-regular fa-tablet"></i>
                    </div>

                    <div class="flex flex-column gap-2">
                        <div class="font-bold">
                            APLICATIVO TABLET
                        </div>
                        
                        <div class="font-bold" v-if="!loading">
                            Versão Atual: {{ tabletApp?.current_version }}
                        </div>

                        <div v-else><i class="fa-duotone fa-spin fa-spinner-third"></i></div>
                    </div>
                </div>

                <Button @click="download(tabletApp.apk_url)" :loading="loading" class="bg-green-400 border-0" icon="fa-brands fa-android" label="BAIXAR" />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {  },

    data() {
        return {
            loading: false,
            appVersions: null,

            sellerApp: null,
            tabletApp: null
        }
    },

    created() {
        this.loadVersions();
    },

    methods: {
        download(route) {
            window.location.href = route;
        },

        loadVersions() {
            this.loading = true;

            this.$api.get('/app_versions')
            .then((response) => {
                this.appVersions = response.data;

                this.sellerApp = this.appVersions.find((app) => {return app.app_name == 'seller_app'});
                this.tabletApp = this.appVersions.find((app) => {return app.app_name == 'tablet_app'});
            })
            .catch((error) => {
                this.responseError(error.response, 'Não foi possível carregar os aplicativos');
            })
            .finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>