<template>
    <div class="layout-footer">
        <div class="col-12">
            <div class="footer-bottom">
                <!-- <h4>Banca única</h4>
                <h6>Copyright Ⓒ PrimeTek Informatics</h6> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppFooter"
};
</script>